import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const ItemsImg = () => {
  return (
    <StaticImage
      src="../../images/items.png"
      quality={100}
      width={550}
      formats={["auto", "webp", "avif"]}
      alt="National Georgaphic"
      placeholder="none"
    />
  );
};
export default ItemsImg;
