import React, { useEffect } from "react";
import { useState } from "react";
import getTotalItems from "../../mdtApi/getTotalItems";
import { StyledTitle } from "../../styles/StyledComponents";
import { useIsMounted } from "../../util/hooks";
import numberWithCommas from "../../util/numberWithCommas";
import styled from "styled-components";
import { colors } from "../../styles/colors";

const LiveTotalTitle = styled(StyledTitle)`
  color: ${colors.white};
  @media only screen and (max-width: 478px) {
    font-size: 38px;
    text-align: center;
  }
`;

const LiveTotalText = styled.span`
  color: ${colors.orange};

  @media only screen and (max-width: 478px) {
    font-size: 38px;
  }
`;

const LiveTotalContainer = styled.div`
  text-align: center;
`;

const LiveTotal = () => {
  const mounted = useIsMounted();
  const [liveTotal, setLiveTotal] = useState<string>("...");

  useEffect(() => {
    const fetchTotal = async () => {
      const response = await getTotalItems();

      if (Number.isInteger(parseInt(response.data))) {
        mounted.current && setLiveTotal(numberWithCommas(response.data));
      }
    };

    try {
      mounted.current && fetchTotal();
    } catch {
      console.error("Something went wrong..");
    }
  }, [mounted]);
  return (
    <LiveTotalContainer>
      <LiveTotalTitle>
        <LiveTotalText>{liveTotal}</LiveTotalText> debris items collected!
      </LiveTotalTitle>
    </LiveTotalContainer>
  );
};

export default LiveTotal;
