require("typeface-lato");
import * as React from "react";

import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import Hero from "../components/Hero/Hero";
import Home from "../components/Home/Home";

const IndexPage = () => {
  return (
    <Layout vertical={true}>
      <Seo title="Home" />
      <Hero />
      <Home />
    </Layout>
  );
};

export default IndexPage;
