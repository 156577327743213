import React from "react";
import { IconImage, AppstoreLink } from "./ApstoreIconComponents";

const AppstoreIcon = ({
  src,
  href,
  apple,
}: {
  src: string;
  href: string;
  apple: boolean;
}) => {
  return (
    <AppstoreLink href={href} target="__blank">
      <IconImage src={src} apple={apple} />
    </AppstoreLink>
  );
};

export default AppstoreIcon;
