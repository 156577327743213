import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { TextContent, StyledTitle } from "../../styles/StyledComponents";

export const HeroImageWrapper = styled.div`
  display: grid;
  position: relative;
  z-index: 1;
  height: 80vh;
  @media only screen and (max-width: 478px) {
    min-height: calc(100vh - 61px);
  }
`;

export const HeroWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 4;
  align-items: flex-start;
  background-position: 0px 0px, 0% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;

  @media only screen and (max-width: 478px) {
    padding-top: 0px;
    margin-bottom: 0px;
    background: none;
    min-height: calc(100vh - 61px);
  }
`;
export const HeroTitle = styled(StyledTitle)`
  margin-bottom: 0px;
  margin-top: 20px;

  @media only screen and (max-width: 767px) {
    font-size: 3em;
  }

  @media only screen and (max-width: 478px) {
    font-size: 2em;
    margin-top: 5vh;
  }
`;

export const HeroSubTitle = styled(StyledTitle)`
  margin-top: 20px;
  margin-bottom: 20px;

  @media only screen and (max-width: 767px) {
    font-size: 2em;
    max-width: 300px;
  }

  @media only screen and (max-width: 478px) {
    font-size: 1.5em;
    margin-top: 10px;
    max-width: 250px;
  }
`;

export const TitleWrapper = styled.div`
  @media only screen and (max-width: 478px) {
  }
`;
export const HeroContent = styled.div`
  width: 100%;
  padding: 100px 65px;
  background-color: transparent;
  z-index: 3;

  @media only screen and (max-width: 767px) {
    padding: 20px;
    padding-top: 0px;
  }

  @media only screen and (max-width: 478px) {
    padding-top: 0px;
    align-items: center;
  }
`;
export const PoweredBy = styled.div`
  display: flex;
  min-width: 600px;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
`;

export const PoweredByText = styled(TextContent)`
  margin-right: 5px;
  @media only screen and (max-width: 991px) {
  }

  @media only screen and (max-width: 767px) {
  }

  @media only screen and (max-width: 478px) {
  }
`;

export const AppIconContainer = styled.div`
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

export const HDImage = styled(StaticImage)`
  grid-area: 1/1;
`;

export const LinGradient = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(270deg, transparent, #fff 80%);

  @media only screen and (max-width: 767px) {
    background-image: none;
    background-color: #ffffff;
    opacity: 0.95;
  }
`;
