import fetch from "isomorphic-fetch";
import { apiUrl } from "../util/urls";
export default async () => {
  try {
    const res = await fetch(`${apiUrl}/getTotal.php`);
    let parsed = res.json();
    return parsed;
  } catch (err) {
    return err;
  }
};
