import React from "react";
import {
  Callout,
  CalloutContentWrapper,
  Emphasis,
  Title,
  TextContent,
  CalloutContentText,
} from "../../styles/StyledComponents";
import PhoneImg from "../ImageComponents/PhoneImg";
import styled from "styled-components";
import mapImage from "../../images/map-example.png";
import Video from "../Video/Video";
import NoaaLogo from "../ImageComponents/NoaaLogo";
import Button from "../Button/Button";
import ItemsImg from "../ImageComponents/ItemsImg";
import FaqContainer from "../Faq/FaqContainer";
import { useQuery, gql } from "@apollo/client";
import LiveTotal from "./LiveTotal";
import { navigate } from "gatsby";

const TextContentLarge = styled.h4`
  max-width: 800px;
  font-family: Lato, sans-serif;
  color: #536275;
  font-size: 28px;
  line-height: 38px;
  font-weight: 400;
  text-align: center;
`;

const MapWrapper = styled.div``;

const MapImg = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
`;

const FAQ_QUESTIONS = gql`
  query GetFAQ {
    faqSection(id: "6HhJv9CARst8DRcDjDhELC") {
      faqQuestionsCollection {
        items {
          question
          answer
        }
      }
    }
  }
`;

const Home = () => {
  const { loading, data: faqDataGQL } = useQuery(FAQ_QUESTIONS);

  return (
    <div>
      <Callout type="grey">
        <CalloutContentWrapper>
          <PhoneImg />
          <CalloutContentText>
            <Title level={2} color="#536275">
              8 million metric tons...
            </Title>
            <TextContent>
              That's how much plastic is estimated to enter the ocean every
              year. What are we doing to change that? <br />‍<br />
              <Emphasis>Debris Tracker</Emphasis> is designed to help citizen
              scientists like you make a difference by contributing data on
              plastic pollution in your community. We've been tracking litter
              since before smartphones existed, and we're still driven by
              uniting technology and citizen science to fight plastic pollution.
              Every day, dedicated educational, non-profit, and scientific
              organizations and passionate citizen scientists from all around
              the world record data on inland and marine debris with our
              easy-to-use app, contributing to our open data platform and
              scientific research.
            </TextContent>
          </CalloutContentText>
        </CalloutContentWrapper>
      </Callout>
      <Callout>
        <LiveTotal />
      </Callout>
      <Callout type="grey">
        <CalloutContentWrapper vertical>
          <Video
            videoTitle="What is Debris Tracker?"
            videoSrcURL={"https://www.youtube.com/embed/lhGvFLLp6dA"}
            width={516}
            height={316}
          />
          <TextContentLarge>
            <Emphasis>Together,</Emphasis> we can create a bigger picture of
            marine debris and plastic pollution through collecting open data,
            generating scientific findings, informing policy, and inspiring
            upstream design.
          </TextContentLarge>
        </CalloutContentWrapper>
      </Callout>
      <MapWrapper>
        <a
          onClick={() => {
            navigate("/data");
          }}
        >
          <MapImg
            src={mapImage}
            sizes="(max-width: 2000px) 100vw, 2000px"
            alt=""
          />
        </a>
      </MapWrapper>
      <Callout type="grey">
        <CalloutContentWrapper>
          <CalloutContentText>
            <Title level={2} color="#536275" centered>
              Data at your fingertips
            </Title>
            <TextContent>
              We believe in open data because we believe the key to solving
              plastic pollution is working together. View all data from around
              the world, or select a customized list from one of our
              collaborators. Filter by date and category, and choose what
              categories to include in your graphs. Inspired solutions begin
              with quality data.
            </TextContent>
            <Button
              onClick={() => {
                navigate("/data");
              }}
              style={{ marginTop: "20px", marginBottom: "20px" }}
              target="__blank"
            >
              Our Data Platform
            </Button>
          </CalloutContentText>
          <ItemsImg />
        </CalloutContentWrapper>
      </Callout>
      <Callout type="white" vertical>
        <CalloutContentWrapper>
          <Video
            style={{ marginRight: "20px" }}
            videoTitle="Tutorial"
            videoSrcURL={"https://www.youtube.com/embed/LzobdbAckws"}
            width={560}
            height={316}
          />
          <CalloutContentText>
            <Title level={2} color="#536275" centered={true}>
              Getting started with Debris Tracker
            </Title>
            <TextContent>
              New here? Let us help you get started. Learn the basics of the app
              and get answers to our most frequently asked questions.
            </TextContent>
          </CalloutContentText>
        </CalloutContentWrapper>
        <Callout type="white" vertical>
          <CalloutContentWrapper>
            <Title centered level={2} color="#536275">
              Frequently Asked Questions
            </Title>
          </CalloutContentWrapper>
          <CalloutContentWrapper>
            {!loading && (
              <FaqContainer
                data={faqDataGQL.faqSection.faqQuestionsCollection.items.map(
                  (item) => {
                    return { question: item.question, answer: item.answer };
                  }
                )}
              />
            )}
          </CalloutContentWrapper>
        </Callout>
      </Callout>
      <Callout type="grey" vertical>
        <CalloutContentWrapper vertical>
          <Title level={2} color="#536275">
            Our Partners
          </Title>
          <CalloutContentWrapper>
            <TextContent>
              Debris Tracker is powered by Morgan Stanley in partnership with
              National Geographic Society and the University of Georgia. Our app
              was developed in 2010 by the NOAA Marine Debris Program and the
              College of Engineering at the University of Georgia. Additional
              support provided by 11th Hour Racing.
            </TextContent>
            <NoaaLogo />
          </CalloutContentWrapper>
        </CalloutContentWrapper>
      </Callout>
    </div>
  );
};

export default Home;
