import styled from "styled-components";

export const AppstoreLink = styled.a`
  margin-right: 8px;
`;

export const IconImage = styled.img`
  width: ${({ apple }) => (apple ? "225px" : "238px")};
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;

  @media only screen and (max-width: 991px) {
  }

  @media only screen and (max-width: 767px) {
    width: ${({ apple }) => (apple ? "190px" : "190px")};
    margin-top: ${({ apple }) => (apple ? "20px" : "0px")};
    margin-bottom: ${({ apple }) => (apple ? "20px" : "0px")};
  }

  @media only screen and (max-width: 478px) {
    width: ${({ apple }) => (apple ? "190px" : "190px")};
    margin-top: ${({ apple }) => (apple ? "20px" : "0px")};
    margin-bottom: ${({ apple }) => (apple ? "20px" : "0px")};
  }
`;
