import React from "react";
import NatGeoImg from "../ImageComponents/NatGeoImg";
import {
  HeroSubTitle,
  AppIconContainer,
  HeroContent,
  HeroTitle,
  HeroWrapper,
  LinGradient,
  PoweredBy,
  PoweredByText,
  TitleWrapper,
  HeroImageWrapper,
} from "./HeroStyledComponents";
import appleApp from "../../images/apple-store-badge.svg";
import googleApp from "../../images/google-play-badge.svg";
import { useDetectDevice } from "../../util/hooks";
import AppstoreIcon from "../ImageComponents/AppstoreIcon";
import { TextContent } from "../../styles/StyledComponents";
import { StaticImage } from "gatsby-plugin-image";

const HeroWrapperWithBackground = ({ children }) => {
  return (
    <HeroImageWrapper>
      <HeroWrapper>
        {children}
      </HeroWrapper>
      <LinGradient />
      <StaticImage 
          src="../../images/hero-background-1.jpg" 
          style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          }}
          alt="hero background image"
          layout="fullWidth"
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
        />
    </HeroImageWrapper>
  );
};

const Hero = () => {
  const { isTablet, isMobile } = useDetectDevice();
  return (
    <HeroWrapperWithBackground>
      <HeroContent>
        {!isTablet && (
          <PoweredBy>
            <PoweredByText>Powered by Morgan Stanley</PoweredByText>
            <NatGeoImg />
          </PoweredBy>
        )}
        <TitleWrapper>
          <HeroTitle
            size={isMobile || isTablet ? "20pt" : "50pt"}
            color="#edbd7f"
          >
            Debris Tracker
          </HeroTitle>
          <HeroSubTitle color="#536275">
            An open data citizen scientist movement
          </HeroSubTitle>
        </TitleWrapper>
        {isTablet && (
          <PoweredBy>
            <TextContent>Powered by Morgan Stanley</TextContent>
            <NatGeoImg />
          </PoweredBy>
        )}
        <AppIconContainer>
          <AppstoreIcon
            src={appleApp}
            href={
              "https://apps.apple.com/us/app/marine-debris-tracker/id432758761?mt=8&amp;ign-mpt=uo%3D4"
            }
            apple={true}
          />
          <AppstoreIcon
            src={googleApp}
            href={
              "https://play.google.com/store/apps/details?id=edu.uga.engr.geolog.marinedebristrack"
            }
            apple={false}
          />
        </AppIconContainer>
      </HeroContent>
    </HeroWrapperWithBackground>
  );
};

export default Hero;
