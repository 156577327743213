import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const PhoneImg = () => {
  return (
    <StaticImage
      src="../../images/MDT-iphones-screenshots.png"
      quality={100}
      width={700}
      formats={["auto", "webp", "avif"]}
      alt="National Georgaphic"
      placeholder="blurred"
    />
  );
};

export default PhoneImg;
