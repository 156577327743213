import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const NatGeoImg = () => {
  return (
    <StaticImage
      src="../../images/National-Geographic-Logo.png"
      quality={100}
      width={125}
      formats={["auto", "webp", "avif"]}
      alt="National Georgaphic"
      placeholder="none"
    />
  );
};

export default NatGeoImg;
