import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const NoaaImgWrapper = styled.div`
  margin-left: 15px;
  @media only screen and (max-width: 478px) {
    margin-left: 0px;
  }
`;

const NoaaLogo = () => {
  return (
    <NoaaImgWrapper>
      <StaticImage
        src="../../images/noa-logo.png"
        quality={100}
        width={150}
        formats={["auto", "webp", "avif"]}
        alt="National Georgaphic"
        placeholder="blurred"
      />
    </NoaaImgWrapper>
  );
};

export default NoaaLogo;
